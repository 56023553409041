import React from "react"
import { Dialog } from "reakit/Dialog"
import { Portal } from "reakit/Portal"
import { Button } from "app/wellzesta-ui"
import CloseIcon from "app/common/icons/Close"
import { useMoodCheckinState } from "./MoodCheckinContext"
import MoodCheckinPage from "./MoodCheckinPage"

export default function FeedbackDialog() {
  const { dialogState, finishCheckin } = useMoodCheckinState()

  return (
    <Portal>
      <Dialog
        {...dialogState}
        aria-label="Feedback"
        hideOnClickOutside={false}
        className={`fixed right-0 bottom-0 mr-0 sm:mr-4 z-40 w-full md:w-70 rounded bg-white shadow-chatDialog`}
        tabIndex={0}
      >
        <Button
          theme="transparent"
          size="sm"
          onClick={finishCheckin}
          className="block ml-auto"
          aria-label="close"
        >
          <CloseIcon className="w-5 h-5" />
        </Button>
        <MoodCheckinPage />
      </Dialog>
    </Portal>
  )
}
