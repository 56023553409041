import { useCurrentUser } from "app/lib/CurrentUser"
import MoodCheckinDialog from "app/features/mood-checkin/MoodCheckinDialog"
import FeedbackWatcherProvider from "app/features/event-feedback/FeedbackContext"
import MoodCheckProvider from "app/features/mood-checkin/MoodCheckinContext"
import FeedbackDialog from "app/features/event-feedback/FeedbackDialog"
import TimeInAppMonitor from "app/features/analytics-score/TimeInAppMonitor"

export function UserModules() {
  const currentUser = useCurrentUser()

  const components = [
    currentUser.hasPermission("event:register") && (
      <FeedbackDialog key="feedback" />
    ),
    currentUser.hasPermission("checkin:create") && (
      <MoodCheckinDialog key="mood" />
    ),
    currentUser.isEndUser && <TimeInAppMonitor key="time" />,
  ].filter(Boolean)

  return components
}

function OrganizationModulesProviders({ children }) {
  return (
    <FeedbackWatcherProvider>
      <MoodCheckProvider>{children}</MoodCheckProvider>
    </FeedbackWatcherProvider>
  )
}

export default OrganizationModulesProviders
