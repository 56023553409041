import { useState } from "react"
import { initPushNotifications, subscribeToInitialTopics, unsubscribeFromTopics } from "app/lib/firebase"
import { useCurrentUser } from "app/lib/CurrentUser"
import { useOrganization } from "app/common/Organization"
import { useNotification } from "app/features/notification/NotificationContext"
import Query from "app/common/Query"
import { gql } from "@apollo/client"

let PushNotifications = () => null

export const QUERY = gql`
  query Events(
    $organizationId: ID!
    $startedFrom: Time
    $startedUntil: Time
  ) {
    organization(id: $organizationId) {
      id
      events(
        startedFrom: $startedFrom
        startedUntil: $startedUntil
        order: ASC
      ) {
        edges {
          node {
            id
            title
            registered
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

if (typeof window !== "undefined") {
  PushNotifications = () => {
    const { startedFrom, startedUntil } = useNotification()
    const user = useCurrentUser()
    const organization = useOrganization()
    const { showNotification } = useNotification()
    const [orgId, setOrgId] = useState()

    if (!organization) {
      return null
    }

    return (
      <Query
        query={QUERY}
        variables={{
          startedFrom,
          startedUntil,
          organizationId: organization.id
        }}
        path={"organization.events"}
        fetchPolicy="cache-first"
      >
        {({ data }) => {
          if (!data || !data.organization) return null

          const events = data?.organization?.events.edges
            .filter(item => item.node.registered)

          const idEventsList = events
            .map(item => item.node.id)

          const nonCorpOrg = organization?.isChildless ?? false

          const firebasePushNotificationsInitializer = async () => {
            if (user.isAuthenticated && nonCorpOrg && organization.id !== orgId) {
              if (orgId) {
                await unsubscribeFromTopics(user.id, orgId, idEventsList)
              }
              setOrgId(organization.id)

              await initPushNotifications(showNotification)
              await subscribeToInitialTopics(user.id, organization.id, idEventsList)
            }
          }
          firebasePushNotificationsInitializer().catch((error) => {
            console.error("Unable to initialize Firebase", error)
          })

          return <></>
        }}
      </Query>
    )
  }
}

export default PushNotifications
