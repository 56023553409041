import reportError from "app/lib/reportError"
import App from "next/app"
import Head from "next/head"
import React from "react"
import Error from "next/error"
import { ApolloProvider } from "@apollo/client"
import { pipe, get, first } from "lodash/fp"
import { URLSearchProvider } from "app/common/URLSearch"
import { Provider as OrganizationProvider } from "app/common/Organization"
import withApollo from "app/lib/withApollo"
import { Provider as CurrentUserProvider } from "app/lib/CurrentUser"
import { Provider as UniversalCtxProvider } from "app/lib/universal-context"
import withUniversalContext from "app/lib/universal-context/withUniversalContext"
import { Online } from "app/lib/Online"
import OfflinePage from "app/common/OfflinePage"
import "app/styles.css"
import "focus-visible"
import { ThemeProvider } from "styled-components"
import defaultTheme from "app/wellzesta-ui/theme"
import OrganizationModulesProviders from "app/lib/OrganizationModulesProvider"
import BasePageLayout from "app/common/Layout"
import CacheProvider from "app/lib/CachedRouter"
import ProductProvider from "app/lib/Product/ProductContext"
import { GoogleAnalytics } from "app/lib/GoogleAnalytics/"
import { UserSessionTracker } from "app/lib/SessionTracker"
import PushNotifications from "app/lib/PushNotifications"
import NotificationProvider from "app/features/notification/NotificationContext"

// avoid requesting organization for paths like robots.txt, apple touch icons and so on
const invalidOrganizationSlug = (slug) => slug && slug.includes(".")

const getOrganizationSlug = (router) => {
  const {
    query: { organizationSlug },
  } = router
  return organizationSlug
}

/**
 * Fixes problem when 100vh doesn’t fit the mobile browser screen.
 * taken from https://github.com/Faisal-Manzer/postcss-viewport-height-correction
 */
function setViewportProperty(doc) {
  let prevClientHeight
  const customVar = "--vh"
  function handleResize() {
    const clientHeight = doc.clientHeight
    if (clientHeight === prevClientHeight) return
    window.requestAnimationFrame(function updateViewportHeight() {
      doc.style.setProperty(customVar, clientHeight * 0.01 + "px")
      prevClientHeight = clientHeight
    })
  }
  handleResize()
  return handleResize
}

let updateVhFn

class BaseApp extends App {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
    }
    props.apolloClient.onError((error) => {
      this.handleError(error)
    })
  }

  static async getInitialProps({ Component, router, ctx }) {
    let pageProps = {}
    const organizationSlug = getOrganizationSlug(router)

    try {
      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx)
      }
    } catch (error) {
      reportError(error, ctx.req)
      return {
        error,
      }
    }

    if (ctx.res) {
      ctx.res.set("Cache-Control", "no-store, must-revalidate")
      if (invalidOrganizationSlug(organizationSlug)) {
        ctx.res.statusCode = 404
      }
    }

    return {
      pageProps,
      organizationSlug,
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      error: props.error || state.error,
    }
  }

  componentDidMount() {
    updateVhFn = setViewportProperty(document.documentElement)
    window.addEventListener("resize", updateVhFn)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", updateVhFn)
  }

  componentDidCatch(error) {
    this.handleError(error)
  }

  handleError(error) {
    reportError(error)
    this.setState({ error: error })
  }

  render() {
    const { error } = this.state
    const {
      Component,
      pageProps,
      apolloClient,
      organizationSlug = getOrganizationSlug(this.props.router),
      nextCtx,
    } = this.props
    const Layout = Component.withNoLayout
      ? ({ children }) => <>{children}</>
      : BasePageLayout

    if (error) {
      let errorType = pipe(get("graphQLErrors"), first, get("type"))(error)
      /**
       * authenticationLink will redirect to login page, so no need to show an error page for NotAuthorized error
       * NotFound error must be handled locally without taking the users out of their current page
       */
      const ignoredErrors = ["NotAuthorized", "NotFound", "NetworkError"]
      if (!error.networkError && !ignoredErrors.includes(errorType)) {
        return <Error statusCode={500} />
      }
    } else if (invalidOrganizationSlug(organizationSlug)) {
      return <Error statusCode={404} />
    }

    return (
      <>
        <GoogleAnalytics />
        <CacheProvider>
          <ThemeProvider theme={defaultTheme}>
            <UniversalCtxProvider value={nextCtx}>
              <ApolloProvider client={apolloClient}>
                <CurrentUserProvider>
                  <OrganizationProvider organizationSlug={organizationSlug}>
                    <ProductProvider>
                      <NotificationProvider>
                        <UserSessionTracker />
                        <OrganizationModulesProviders>
                          <URLSearchProvider router={this.props.router}>
                            <PushNotifications />
                            <Online>
                              {(online) =>
                                online ? (
                                  <Layout>
                                    <Head>
                                      <meta
                                        name="viewport"
                                        content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0"
                                      />
                                    </Head>
                                    <Component {...pageProps} />
                                  </Layout>
                                ) : (
                                  <OfflinePage />
                                )
                              }
                            </Online>
                          </URLSearchProvider>
                        </OrganizationModulesProviders>
                      </NotificationProvider>
                    </ProductProvider>
                  </OrganizationProvider>
                </CurrentUserProvider>
              </ApolloProvider>
            </UniversalCtxProvider>
          </ThemeProvider>
        </CacheProvider>
      </>
    )
  }
}

export default withUniversalContext(withApollo(BaseApp))
