import React from "react"
import Router from "next/router"
import Head from "next/head"
import getConfig from "next/config"
import { Button } from "app/wellzesta-ui"
import startCase from "lodash/fp/startCase"
import Header from "app/common/Header"
import SideNavbar from "app/common/SideNavbar"
import MenuIcon from "app/common/icons/Menu"
import { UserModules } from "app/lib/OrganizationModulesProvider"
import { useProduct } from "app/lib/Product/ProductContext"
import {
  useDialogState,
  Dialog,
  DialogDisclosure,
  DialogBackdrop,
} from "reakit/Dialog"
import styles from "./Layout.module.css"
import NotificationDialog from "app/features/notification/NotificationDialog"

const { publicRuntimeConfig } = getConfig()
const REACH_APPLE_STORE_ID = publicRuntimeConfig.REACH_APPLE_STORE_ID
const LIFE_APPLE_STORE_ID = publicRuntimeConfig.LIFE_APPLE_STORE_ID

function getAppleAppId({ isReach, isLife }) {
  if (isReach) {
    return REACH_APPLE_STORE_ID
  }
  if (isLife) {
    return LIFE_APPLE_STORE_ID
  }
  return null
}

function Layout({ children }) {
  const dialog = useDialogState({ animated: true })
  const { productDomain, isReach, isLife } = useProduct()
  const appleAppId = getAppleAppId({ isLife, isReach })

  Router.events.on("routeChangeComplete", () => dialog.hide())

  return (
    <>
      <Head>
        <title>{startCase(`Wellzesta ${productDomain || "Life"}`)}</title>
        {isReach || isLife ? (
          <meta name="apple-itunes-app" content={`app-id=${appleAppId}`} />
        ) : null}
      </Head>
      <div>
        <Header
          openSidebarButton={
            <DialogDisclosure
              data-test-id="open-sidebar-button"
              as={Button}
              theme="is-inverse"
              size="sm"
              {...dialog}
              aria-label="open menu"
              className="mr-2 md:mr-4 min-w-0 flex-shrink-0 fon"
            >
              <MenuIcon className="w-6 md:w-6" />
            </DialogDisclosure>
          }
        />
        {children}
        <UserModules />
        <NotificationDialog key="notification" />
        <DialogBackdrop {...dialog} className={styles.sidebarBackdrop}>
          <Dialog
            {...dialog}
            aria-label="Navigation bar"
            className={`${styles.sidebar} bg-inverse w-65`}
            preventBodyScroll
            hideOnClickOutside
            hideOnEsc
            tabIndex={0}
          >
            <SideNavbar />
          </Dialog>
        </DialogBackdrop>
      </div>
    </>
  )
}

export default Layout
