import { Button, Text } from "app/wellzesta-ui"

export const NotificationBody = ({ notification, onConfirm, onViewDetail }) => {
  return (
    <>
      <Text size="md" className="block font-semibold">
        {
          notification.body
        }
      </Text>
      <div className="mt-4 flex justify-end space-x-3">
        <Button
          theme="inverse"
          size="md"
          onClick={() =>
            onViewDetail()
          }
        >
          View detail
        </Button>
        <Button
          theme="tertiary"
          size="md"
          onClick={() =>
            onConfirm()
          }
        >
          Ok
        </Button>
      </div>
    </>
  )
}
