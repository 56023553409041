import React from "react"

const Menu = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <g fill="currentColor">
      <rect x="0" y="3" width="100%" height="2" />
      <rect x="0" y="11" width="100%" height="2" />
      <rect x="0" y="19" width="100%" height="2" />
    </g>
  </svg>
)

export default Menu
