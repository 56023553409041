import React from "react"
import { Dialog } from "reakit/Dialog"
import { Portal } from "reakit/Portal"
import { Heading, Button } from "app/wellzesta-ui"
import CloseIcon from "app/common/icons/Close"
import { useFeedbackState } from "./FeedbackContext"

export default function FeedbackDialog() {
  const { dialogState, close, feedback } = useFeedbackState()

  return (
    <Portal>
      <Dialog
        {...dialogState}
        aria-label="Feedback"
        hideOnClickOutside={false}
        className="fixed right-0 bottom-0 mr-0 sm:mr-4 z-40 w-full md:w-70 rounded shadow-chatDialog bg-white"
        tabIndex={0}
      >
        <Button
          theme="transparent"
          size="sm"
          onClick={close}
          className="block ml-auto"
          aria-label="close"
        >
          <CloseIcon className="w-5 h-5" />
        </Button>
        <div className="px-4 pb-4">
          <Heading size="sm" className="mb-4 font-bold">
            {feedback?.title}
          </Heading>
          {feedback?.bodyElement}
        </div>
      </Dialog>
    </Portal>
  )
}
