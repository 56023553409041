import React, { Component, createContext, useContext } from "react"
import queryString from "query-string"
import { reduce } from "lodash"

const URLSearchContext = createContext()
const { Provider, Consumer: URLSearch } = URLSearchContext

export class URLSearchProvider extends Component {
  get search() {
    const href = this.props.router.asPath
    const searchIndex = href.indexOf("?")

    if (searchIndex > -1) {
      const search = href.substr(searchIndex + 1, href.length)
      return queryString.parse(search, { arrayFormat: "index" }) || {}
    }

    return {}
  }

  setSearch = values => {
    const { router } = this.props

    const search = queryString.stringify(
      reduce(
        {
          ...this.search,
          ...values
        },
        (acc, value, key) =>
          value === null || typeof value === "undefined"
            ? acc
            : {
                ...acc,
                [key]: value
              },
        {}
      ),
      { arrayFormat: "index" }
    )

    const searchIndex = router.asPath.indexOf("?")

    const pathname =
      searchIndex < 0 ? router.asPath : router.asPath.substr(0, searchIndex)

    const path = `${pathname}?${search}`
    router.push(router.route, path, { shallow: true })
  }

  render() {
    return (
      <Provider
        value={{
          search: this.search,
          setSearch: this.setSearch
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}

export function withURLSearch(WrappedComponent) {
  return function(props) {
    return (
      <URLSearch>
        {urlSearchState => <WrappedComponent {...props} {...urlSearchState} />}
      </URLSearch>
    )
  }
}

export function useURLSearch() {
  const { search, setSearch } = useContext(URLSearchContext)
  return [search, setSearch]
}
