import { gql, useMutation } from "@apollo/client"
import { useOrganization } from "app/common/Organization"
import { useEffect } from "react"
import { useCurrentUser, userTypes } from "app/lib/CurrentUser"
import { useLazyViewMessage } from "./useViewMessage"

export const createAnalyticsEntryMutation = gql`
  mutation CreateAnalyticsEntry(
    $organizationId: ID!
    $event: AnalyticsEventName!
  ) {
    createAnalyticsEntry(organizationId: $organizationId, event: $event)
  }
`

export default function useCreateAnalyticsEntry() {
  const organization = useOrganization()
  const [createAnalyticsEntry] = useMutation(createAnalyticsEntryMutation)

  return (eventName) =>
    createAnalyticsEntry({
      variables: { organizationId: organization.id, event: eventName },
    })
}

export function useLazyAnalyticsScore({ withDelay } = { withDelay: false }) {
  const user = useCurrentUser()
  const shouldTrackAnalyticsEvents =
    user.userType === userTypes.DEFAULT ||
    user.userType === userTypes.FAMILY_MEMBER
  const organization = useOrganization()
  const [viewMessageMutation] = useLazyViewMessage()
  const createAnalyticsEntry = useCreateAnalyticsEntry()

  const dispatchAnalytic = ({ analyticsEventName, viewMessage }) => {
    if (shouldTrackAnalyticsEvents) {
      createAnalyticsEntry(analyticsEventName)
      if (viewMessage?.type && user.hasPermission("message:view")) {
        viewMessageMutation({
          variables: {
            type: viewMessage.type,
            id: viewMessage.id,
            organizationId: organization.id,
          },
        })
      }
    }
  }

  return ({ analyticsEventName, viewMessage }) => {
    if (withDelay) {
      return setTimeout(
        () => dispatchAnalytic({ analyticsEventName, viewMessage }),
        5000
      )
    }
    return dispatchAnalytic({ analyticsEventName, viewMessage })
  }
}

export function useAnalyticsScore({ analyticsEventName, viewMessage }) {
  const user = useCurrentUser()
  const shouldTrackAnalyticsEvents =
    user.userType === userTypes.DEFAULT || userTypes.FAMILY_MEMBER
  const dispatchAnalyticsScore = useLazyAnalyticsScore({ withDelay: true })

  useEffect(() => {
    let timer = null
    if (shouldTrackAnalyticsEvents) {
      timer = dispatchAnalyticsScore({ analyticsEventName, viewMessage })
    }
    return () => clearTimeout(timer)
    /**
     * MessageDetails component re-renders right after this hook mutations are executed.
     * That's what we need to prevent, making it only runs once.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
