import React, { useCallback, useContext, useState } from "react"
import { useDialogState } from "reakit/Dialog"
import { NotificationBody } from "app/features/notification/NotificationBody"
import Router from "next/router"
import moment from "moment"

const NotificationContext = React.createContext(null)

const NotificationProvider = ({ children }) => {
  const dialog = useDialogState({ modal: false })
  const [notification, setNotification] = useState(null)
  const [startedFrom] = useState(moment().subtract(15, "days").format())
  const [startedUntil] = useState(moment().add(15, "days").format())

  const showNotification = useCallback((firebaseNotification) => {
    const { title } = firebaseNotification.notification

    if (firebaseNotification.notification.tag) {
      setNotification({
        title,
        bodyElement: (
          <NotificationBody
            notification={firebaseNotification.notification}
            onConfirm={() => {
              setNotification(null)
              dialog.hide()
            }}
            onViewDetail={() => {
              Router.push(firebaseNotification.notification.tag)
              dialog.hide()
            }}
          />
        )
      })

      dialog.show()
    }

  }, [
    setNotification,
    dialog,
    notification
  ])

  const closeNotification = useCallback(() => {
    dialog.hide()
    setNotification(null)
  }, [dialog])

  return (
    <NotificationContext.Provider
      value={{
        dialogState: dialog,
        close: closeNotification,
        showNotification,
        notification,
        startedFrom,
        startedUntil
      }}>
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => useContext(NotificationContext)

export default NotificationProvider
