import { Text } from "app/wellzesta-ui"
import { useCurrentUser } from "app/lib/CurrentUser"
import FeedbackFigure from "./FeedbackFigure"
import styles from "./CheckinCompletedStep.module.css"
import { useEffect, useState } from "react"

const MOOD_FEEDBACK = {
  TERRIBLE: "We all have bad days sometimes. We hope it gets better.",
  NOT_GOOD: "Sending you good vibes for a better day.",
  AS_USUAL: "It's ok just being ok. Hope tomorrow is brighter.",
  GOOD: "Keep the good vibes going!",
  GREAT: "You rock! Have an amazing day!",
}

export default function CheckinCompletedStep({ mood }) {
  const currentUser = useCurrentUser()
  const [feedbackClasses, setFeedbackClasses] = useState(undefined)

  useEffect(() => {
    let animationTimer
    if (mood) {
      animationTimer = setTimeout(() => {
        setFeedbackClasses(styles.highlighted)
      }, 1000)
    }
    return () => clearTimeout(animationTimer)
  }, [mood])

  if (!mood) {
    return null
  }

  return (
    <div
      className={`mb-6 flex flex-col items-center space-y-3 ${styles.fadeIn}`}
    >
      <FeedbackFigure mood={mood} />
      <Text
        size="md"
        as="p"
        className="font-bold"
      >{`Thank you, ${currentUser.firstName}`}</Text>
      <Text
        size="md"
        className={`font-normal text-center ${styles.highlight} ${feedbackClasses}`}
      >
        {MOOD_FEEDBACK[mood]}
      </Text>
    </div>
  )
}
