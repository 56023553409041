import React from "react"
import { useRouter } from "next/router"
import clsx from "clsx"
import { useOrganization } from "app/common/Organization"
import InternalLink from "app/common/InternalLink"
import OrganizationIcon from "app/common/icons/Organization"
import { Button, Text } from "app/wellzesta-ui"
import { useProduct } from "app/lib/Product/ProductContext"
import {
  useDisclosureState,
  Disclosure,
  DisclosureContent,
} from "reakit/Disclosure"
import styles from "./SideNavbar.module.css"
import { version } from "../../../package.json"

function SideNavbar() {
  const organization = useOrganization()
  const { routes } = useProduct()
  const router = useRouter()
  const { route } = router
  return (
    <div
      data-test-id="sidebar"
      className="text-xl text-primary h-full flex flex-col"
    >
      <header className="flex-shrink-0 flex items-center p-4">
        <div className="pr-4 flex-shrink-0">
          <OrganizationIcon
            className="w-8 h-8 rounded-full"
            logoUrl={
              organization?.id === "1"
                ? "/static/images/avafield.png"
                : organization.logoUrl
            }
          />
        </div>
        <Text size="sm" className="font-bold text-black truncate">
          {organization?.name}
        </Text>
      </header>
      <nav aria-label="primary" role="navigation">
        {routes.map((item) => {
          if (item.items) {
            return (
              <NavSubmenuItem
                key={item.label}
                {...item}
                organizationSlug={organization?.slug}
                currentRoute={route}
              />
            )
          }
          if (item.label === "Campus Life") {
            return <NavSegment key={item.label} {...item} />
          } else {
            return (
              <NavLink
                key={item.label}
                {...item}
                organizationSlug={organization?.slug}
                currentRoute={route}
              />
            )
          }
        })}
      </nav>
      <footer className="mt-auto">
        <span className="px-4 text-xs text-gray-400 font-semibold">
          V {version}
        </span>
      </footer>
    </div>
  )
}

function NavSubmenuItem({ label, items, organizationSlug, currentRoute }) {
  const isChildActive = items.some((it) => it.href === currentRoute)
  const disclosure = useDisclosureState({
    visible: isChildActive,
    animated: true,
  })

  return (
    <div className="">
      <Disclosure
        as={Button}
        size="sm"
        theme="inverse"
        {...disclosure}
        className={`transition-colors duration-300 ease-in-out flex w-full items-center ${styles.navItem} text-black font-semibold justify-between h-16 rounded-none`}
      >
        {label}
        <ChevronIcon
          className={`${styles.navSubmenuIcon} ${
            disclosure.visible && styles.isExpanded
          }`}
        />
      </Disclosure>
      <DisclosureContent
        {...disclosure}
        className={clsx(
          isChildActive ? "bg-white" : "bg-transparent",
          styles.navSubmenu
        )}
      >
        {items.map(({ label, href }) => (
          <NavLink
            key={label}
            label={label}
            href={href}
            organizationSlug={organizationSlug}
            currentRoute={currentRoute}
            isSubmenuItem
            target={label === "App Support" ? "_blank" : ""}
          />
        ))}
      </DisclosureContent>
    </div>
  )
}

function NavLink({
  label,
  href,
  organizationSlug,
  currentRoute,
  isSubmenuItem,
  target,
}) {
  const isActive = href === currentRoute
  const as = href
    ? href.replace("[organizationSlug]", organizationSlug)
    : "this_is_link"
  const theme = isActive
    ? styles.navActiveItem
    : isSubmenuItem
    ? styles.navSubItem
    : styles.navInactiveItem

  return (
    <InternalLink
      href={href}
      as={as}
      className={`transition-colors duration-300 ease-in-out h-16 ${theme} py-1 text-2x`}
      aria-label={label}
      {...(isActive && { "aria-current": "page" })}
      target={target}
    >
      <span className={isSubmenuItem ? "pl-3" : undefined}>{label}</span>
    </InternalLink>
  )
}

function NavSegment({ label }) {
  return (
    <div className={"h-16 py-4"}>
      <span
        style={{ color: "var(--color-bg-even-brighter)" }}
        className={"font-semibold px-4"}
      >
        {label}
      </span>
    </div>
  )
}

const ChevronIcon = ({ className }) => (
  <svg
    className={className}
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33333 5L7.15833 6.175L10.975 10L7.15833 13.825L8.33333 15L13.3333 10L8.33333 5Z"
      fill="currentColor"
    />
  </svg>
)

export default React.memo(SideNavbar)
