export const getGATrackingIDByProduct = (productName) => {
  return {
    reach: 'G-CQT2V7ST9V',
    life: 'G-7HS62TH8DK',
    connect: 'G-VLVXHH26LN'
  }[productName]
}

export const pageView = (url, gaTrackingID) => {
  window.gtag('config', gaTrackingID, {
    page_path: url
  });
};
